<template>
  <div>
    <div class="tabs" :class="{ 'tabs--center': tabsCenter }">
      <div class="content-layout-fixer">
        <ul>
          <li v-for="tab in tabs" :key="tab.name">
            <a
              href="javascript:;"
              class="tabs__button"
              :class="{
                active: tab.isActive,
                'icon-active': !!tab.iconName,
                'narrow-icon': tab.iconSize.width < 15,
              }"
              @click="selectTab(tab)"
            >
              <span v-if="!!tab.iconName" class="tabs__icon">
                <VueIcon
                  :iconColor="'#333'"
                  :width="tab.iconSize.width"
                  :height="tab.iconSize.height"
                  :iconName="tab.iconName"
                />
              </span>
              <VueText sizeLevel="5" weightLevel="2">
                {{ tab.name }}
              </VueText>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-layout-fixer">
      <div class="ad-wrapper-large">
        <div id="div-gpt-ad-1587046762079-0"></div>
      </div>
    </div>
    <div class="tabs-details">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { COMPONENT_SIZES } from '@/constants/component.constants.js';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import gtmUtils from '@/mixins/gtmUtils.js';

export default {
  mixins: [gtmUtils],
  components: {
    VueText,
    VueIcon,
  },
  props: {
    tabsCenter: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return { tabs: [] };
  },
  computed: {
    sizes() {
      return COMPONENT_SIZES;
    },
  },
  mounted() {
    this.$slots.default.forEach(item => {
      if (item.componentInstance.enabled) {
        this.tabs.push(item.componentInstance);
      }
    });
    this.pushAds('large');
    this.$emit('selectedTab', 0, this.tabs[0].name);
  },
  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach((tab, index) => {
        let $isActiveTab = tab.name == selectedTab.name;
        tab.isActive = $isActiveTab;
        if ($isActiveTab) {
          this.$emit('selectedTab', index, tab.name);
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.tabs {
  width: 100%;
  overflow: auto;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 0 0 30px;

  ul {
    display: flex;
    list-style: none;

    li {
      z-index: 1;
      margin-right: palette-space-level('30');

      a:visited {
        color: inherit;
      }
    }
  }

  &__button {
    display: block;
    outline: none;
    text-decoration: none;
    color: palette-color-level('grey', '30');

    & > p {
      padding-bottom: palette-space-level('10');
    }

    &:after {
      display: block;
      content: ' ';
      width: 100%;
      height: 2px;
    }

    &.active {
      color: palette-color-level('grey', '40');

      &:after {
        @include bg-linear-gradient(
          to left,
          palette-color-level('fushia', '20') 0%,
          palette-color-level('yellow', '30') 100%
        );
      }
    }

    &.icon-active {
      padding-left: palette-space-level('25');
      &.narrow-icon {
        padding-left: palette-space-level('20');
      }
      position: relative;
      & > * {
        display: inline-block;
      }

      &:after {
        width: calc(100% + 25px);
        margin-left: -25px;
      }
    }

    ::v-deep g.icon-color {
      fill: palette-color-level('grey', '30') !important;
    }

    ::v-deep &.active g.icon-color {
      fill: palette-color-level('grey', '40') !important;
    }
  }

  &__icon {
    position: absolute;
    left: 0;
    top: 0;
  }

  &__icons {
    width: 16px;
    height: 16px;
  }

  &--center {
    max-width: none;
    border-bottom: 1px solid palette-color-level('grey', '15');

    & > div > ul {
      justify-content: center;
    }
  }
}
</style>
