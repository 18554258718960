<template>
  <div v-show="isActive">
    <div v-if="headerTitle && headerImage" class="tab-header">
      <BrandLogo :image="headerImage" />
      <div class="tab-headlines">
        <VueText color="grey-30" sizeLevel="3">Philip Morris</VueText>
        <VueText color="grey-40" sizeLevel="6">{{ headerTitle }}</VueText>
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    name: { required: true },
    selected: { default: false },
    id: { required: true },
    headerTitle: { required: false },
    headerImage: { required: false },
    enabled: { default: true },
    iconName: {
      required: false,
      type: String,
    },
    iconSize: {
      required: false,
      type: Object,
      default: () => ({
        width: 18,
        height: 18,
      }),
    },
  },

  data() {
    return {
      isActive: false,
    };
  },
  // watch: {
  //   selected: {
  //     handler(val) {
  //       this.isActive = val;
  //     },
  //   },
  // },
  computed: {
    href() {
      return '#' + this.name.toLowerCase().replace(/ /g, '-');
    },
  },

  mounted() {
    this.isActive = this.selected;
  },
};
</script>
